import { isValidElement } from 'react'
import Link from 'next/link'
import { SlideText } from '../type'
import styles from './hero.module.scss'
import classnames from 'classnames/bind'
import Text from 'components/type/new'

const cx = classnames.bind(styles)

function Title({ children, link }) {
  const contents = isValidElement(children) ? (
    <div className={styles['title']}>{children}</div>
  ) : (
    <SlideText className={styles['title']}>{children}</SlideText>
  )
  if (!link) {
    return contents
  }

  return <Link href={link}>{contents}</Link>
}

const HeroCard = ({
  callToAction,
  className = '',
  children,
  link,
  title,
  style,
}: {
  callToAction?: React.ReactNode
  className?: string
  children?: React.ReactNode
  link: string
  title: string | React.ReactNode
  style?: React.CSSProperties
}) => {
  return (
    <div className={cx(styles.container, className)} style={{ ...style }}>
      {children}
      <Title link={link}>{title}</Title>
      <div className={styles.action}>{callToAction}</div>
    </div>
  )
}

export default HeroCard

export function HeroSecondary({ children, title }) {
  return (
    <div className={styles.container}>
      {children}
      <div className={cx('title', 'secondary')}>
        <Text size="xl">{title}</Text>
      </div>
    </div>
  )
}
