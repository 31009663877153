import styles from './type.module.scss'
import cx from 'classnames'

const Type = ({ children }) => <p>{children}</p>
export default Type

export const Headline = ({ children, color, className, style }) => {
  const inlineStyles = { '--color': color, ...style }
  return (
    <h1 className={cx(styles.headline, className)} style={inlineStyles}>
      {children}
    </h1>
  )
}

export const SlideText = ({ children, className, style = {} }) => (
  <h2 className={cx(styles.headline, className)} style={style}>
    {children}
  </h2>
)

export const SlideSecondary = ({ children, className }) => (
  <h2 className={cx(styles['slide-secondary'], className)}>{children}</h2>
)

export const Title = ({ children, color, className, style }) => {
  const inlineStyles = { '--color': color, ...style }
  return (
    <h2 className={cx(styles.title, className)} style={inlineStyles}>
      {children}
    </h2>
  )
}

export const Heading = ({ children, ...props }) => (
  <h5 className={styles['heading']} {...props}>
    {children}
  </h5>
)

export const Body = ({
  children,
  className,
  color,
  linkColor = '#ffffff',
  style,
}) => {
  const inlineStyles = { '--color': color, '--linkColor': linkColor, ...style }
  return (
    <div className={cx(styles.body, className)} style={inlineStyles}>
      {children}
    </div>
  )
}

export const Text = ({ children }) => <p className={styles.text}>{children}</p>

export const List = ({ children }) => (
  <ul className={styles.list}>{children}</ul>
)

export const ListItem = ({ children, className = [] }) => (
  <li className={cx(styles['list-item'], className)}>{children}</li>
)

export const Underline = ({ children }) => {
  return <span className={styles.underline}>{children}</span>
}

export const EditionSecondary = ({ children, style = {} }) => {
  return (
    <h3 className={styles['edition-secondary']} style={style}>
      {children}
    </h3>
  )
}
