import lazySizes from 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'
import classnames from 'classnames/bind'
import styles from './images.module.scss'
import React from 'react'

const cx = classnames.bind(styles)

export const Image = ({
  src,
  lazy,
  style = {},
  alt = '',
  className = '',
  ...props
}) => {
  const srcAttribute = lazy ? 'data-src' : 'src'
  const imgProps = {
    [srcAttribute]: src,
    className: cx(className, { lazyload: lazy }),
    style,
    alt,
    ...props,
  }
  return <img alt={alt} {...imgProps} />
}

type PictureProps = {
  fit?: 'cover' | 'contain' | 'scaled' | 'none'
  position?: 'top' | 'right' | 'bottom' | 'top' | 'left' | 'top-left'
  style?: React.CSSProperties
  width?: number
  height?: number
}

export const Picture: React.FC<React.PropsWithChildren<PictureProps>> = ({
  children,
  fit = 'cover',
  position,
  style = {},
  ...props
}) => {
  return (
    <picture className={cx('picture', fit, position)} style={style} {...props}>
      {children}
    </picture>
  )
}

export const Source = ({ srcSet, lazy, ...props }) => {
  const srcAttribute = lazy ? 'data-srcset' : 'srcSet'
  const sourceProps = { [srcAttribute]: srcSet, ...props }
  return <source {...sourceProps} />
}

export default Image
