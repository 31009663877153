import cx from 'classnames'
import styles from './background.module.scss'
import { Picture, Source, Image } from '../images'
import { BackgroundVideo } from '../video'

export default function BackgroundImage({
  className = '',
  imgClassName = '',
  alt = '',
  options = [],
  fallback = '',
  lazy = false,
  position = '',
  fit = 'cover',
  style = {},
  imageStyle = {},
  ...props
}) {
  const classNames = cx(styles.background, className)
  return (
    <div className={classNames} style={style}>
      <Picture fit={fit} position={position}>
        {options.map((opt, i) => (
          <Source key={i} {...opt} lazy={lazy} />
        ))}
        <Image
          {...fallback}
          lazy={lazy}
          className={imgClassName}
          style={imageStyle}
          alt={alt}
          role="presentation"
          {...props}
        />
      </Picture>
    </div>
  )
}

export function BackgroundVid({ fit = '', src, style, ...props }) {
  const classNames = cx(styles.background, { [styles[`fit-${fit}`]]: fit })
  return (
    <div className={classNames} style={style}>
      <BackgroundVideo src={src} {...props} />
    </div>
  )
}
