import { useEffect, useState } from 'react'
import { VolumeOff, VolumeOn } from '../icon'
import styles from './video.module.scss'

export default function Video({ src, controls = [], style = {} }) {
  const [muted, setMute] = useState(true)

  return (
    <div style={{ position: 'relative' }}>
      <video
        data-src={src}
        autoPlay
        loop
        muted={muted}
        playsInline
        style={style}
        preload=""
        className="lazyload"
      />
      {controls.includes('mute') ? (
        <button
          aria-label="Mute"
          className={styles.button}
          onClick={() => setMute(!muted)}
        >
          {muted ? <VolumeOff /> : <VolumeOn />}
        </button>
      ) : null}
    </div>
  )
}

export function BackgroundVideo({
  options = [],
  lazy = true,
  attributes = {},
  poster,
  src,
  style,
}) {
  const [loaded, setLoading] = useState(false)
  const [srcValue, setSrcValue] = useState({ poster, src })

  const defaults = {
    autoPlay: true,
    loop: true,
    muted: true,
    playsInline: true,
    preload: '',
  }
  const videoAttributes = { ...defaults, ...attributes }
  const srcAttribute = lazy ? 'data-src' : 'src'
  if (lazy) {
    videoAttributes['className'] = 'lazyload'
  }
  videoAttributes[srcAttribute] = srcValue.src

  useEffect(() => {
    setLoading(true)
  }, [loaded])

  useEffect(() => {
    const hasWindow = typeof window !== 'undefined'
    if (hasWindow) {
      const matchedMedia = options.find(
        (o) => window.matchMedia(o.media).matches
      )
      const value = matchedMedia || { poster, src }
      setSrcValue(value)
    }
  }, [options, poster, src])

  if (!loaded) {
    return null
  }

  return (
    <div>
      <video poster={srcValue.poster} {...videoAttributes} style={style} />
    </div>
  )
}
