import React, { Fragment, useState } from 'react'
import styles from './carousel.module.scss'
import { useKeenSlider } from 'keen-slider/react'
import cn from 'classnames'
import HeroCard from 'components/cards/hero'
import Type from 'components/type/v3'
import Background from 'components/cards/background'
import { DAC } from 'components/logo/misc'
import Head from 'next/head'

const DEFAULTS = {
  inFrame: false,
}

function Dots({ active, onUpdate, size, inFrame }) {
  const dotStyles = cn(styles['dots'], { [styles['in-frame']]: inFrame })

  return (
    <div className={dotStyles}>
      {/* @ts-ignore */}
      {[...Array(size).keys()].map((idx) => {
        const isActive = active === idx
        const dotClasses = [styles['dot']]
        if (isActive) {
          dotClasses.push(styles['active'])
        }

        const dotStyles = dotClasses.join(' ')
        return (
          <button
            key={idx}
            onClick={() => onUpdate(idx)}
            className={dotStyles}
          />
        )
      })}
    </div>
  )
}

function Carousel({ children, options = DEFAULTS, ...props }) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loading, setLoading] = useState(true)
  const [sliderRef, instanceRef] = useKeenSlider({
    created() {
      setLoading(false)
    },
    loop: true,
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
  })

  const sliderClass = `keen-slider${loading ? ' loading' : ''}`

  return (
    <div className={styles['container']} {...props}>
      <div ref={sliderRef} className={sliderClass}>
        {children}
      </div>
      {instanceRef && (
        <Dots
          active={currentSlide}
          onUpdate={instanceRef.current?.moveToIdx}
          size={instanceRef.current?.slides?.length}
          inFrame={options.inFrame}
        />
      )}
    </div>
  )
}

export default Carousel

export function Slide({ children }) {
  return (
    <div className={`keen-slider__slide ${styles['slide']}`}>{children}</div>
  )
}

const getTitle = (title: string) => {
  try {
    return title.split('\n').map((item, key) => (
      <Fragment key={key}>
        {item}
        <br />
      </Fragment>
    ))
  } catch (err) {
    return null
  }
}

type SlideImage = {
  size: string
  url: string
}

export function HomepageSlide({
  color = '#ffffff',
  id,
  images,
  preload = false,
  title,
  url,
}: {
  color: string
  id?: string
  title: string
  preload: boolean
  images: SlideImage[]
  url: string
}) {
  const fallbackImg = images.find((img) => img.size === 'fallback')
  const imgOptions = images
    .filter((img) => img.size !== 'fallback')
    .map((img) => ({
      media: img.size,
      srcSet: img.url,
    }))
  if (id === 'slide-os') {
    return (
      <div className={`keen-slider__slide ${styles['homepage-slide']}`}>
        <HeroCard
          title={
            <div className={styles['aos_logo']}>
              <svg
                width="275"
                height="99"
                viewBox="0 0 275 99"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#a)">
                  <path d="M24.585 53.099a23.179 23.179 0 0 0-13.368 3.233A23.01 23.01 0 0 0 2.07 66.558 22.87 22.87 0 0 0 .393 80.145a22.936 22.936 0 0 0 6.388 12.124 23.125 23.125 0 0 0 12.184 6.356c4.616.838 9.38.255 13.655-1.669a23.05 23.05 0 0 0 10.276-9.1 22.883 22.883 0 0 0 3.25-13.303 22.928 22.928 0 0 0-6.717-14.771 23.144 23.144 0 0 0-14.844-6.683ZM104.631 64.532 73.856 11.49A23.078 23.078 0 0 0 59.837.787a23.193 23.193 0 0 0-17.52 2.296 23.046 23.046 0 0 0-6.773 5.91 22.922 22.922 0 0 0-3.984 8.038 22.856 22.856 0 0 0 2.308 17.431l30.775 53.071a23.077 23.077 0 0 0 14.02 10.706 23.193 23.193 0 0 0 17.521-2.293 23.049 23.049 0 0 0 6.783-5.915 22.936 22.936 0 0 0 3.988-8.049 22.85 22.85 0 0 0 .585-8.955 22.905 22.905 0 0 0-2.908-8.495ZM163.974 6.386c21.907 0 43.938 16.508 43.938 46.37 0 29.86-22.031 46.244-43.938 46.244-22.031 0-43.813-16.373-43.813-46.245 0-29.872 21.782-46.37 43.813-46.37Zm0 85.303c18.236 0 35.836-13.482 35.836-38.956 0-25.576-17.589-39.058-35.836-39.058-18.36 0-35.705 13.482-35.705 39.058 0 25.474 17.345 38.956 35.705 38.956ZM267.023 30.832c-2.026-9.073-8.233-17.389-21.907-17.389-10.638 0-20.517 7.057-20.517 17.643 0 8.063 5.067 13.607 14.185 15.747l13.674 3.144C266.518 53.365 275 61.32 275 73.916c0 13.612-11.648 25.079-29.759 25.079-19.626 0-30.769-12.856-32.159-26.587l7.489-2.388c1.016 11.591 10.003 21.799 24.693 21.799 13.804 0 21.782-7.56 21.782-17.265 0-8.44-5.828-14.363-16.086-16.757l-13.543-3.15c-12.057-2.648-20.818-10.457-20.818-23.058 0-13.736 13.425-25.203 28.239-25.203 18.491 0 26.593 10.462 29.254 21.928l-7.069 2.518Z" />
                </g>
                <defs>
                  <clipPath id="a">
                    <path d="M0 0h275v99H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          }
          link={url}
        />
        <Background
          options={imgOptions}
          // @ts-ignore
          fallback={{
            src: fallbackImg?.url,
          }}
          fit="cover"
        />
      </div>
    )
  }
  if (id === 'slide-dac') {
    return (
      <div className={`keen-slider__slide ${styles['homepage-slide']}`}>
        <HeroCard
          title={
            <Type size="xl" el="h2">
              <DAC className={styles.dac_title} /> to basics
            </Type>
          }
          link={url}
        />
        <Background
          options={imgOptions}
          // @ts-ignore
          fallback={{
            src: fallbackImg?.url,
          }}
          fit="cover"
        />
      </div>
    )
  }
  if (id === 'slide-openfpga') {
    return (
      <div className={`keen-slider__slide ${styles['homepage-slide']}`}>
        <HeroCard
          title={
            <div className={styles['openfpga-text']}>
              <Type size="xl" el="h2" style={{ color }}>
                openFPGA. The future of video game preservation.
              </Type>
            </div>
          }
          link={url}
        />
        <Background
          options={imgOptions}
          // @ts-ignore
          fallback={{
            src: fallbackImg?.url,
          }}
          fit="cover"
        />
      </div>
    )
  }
  if (id === 'slide-3d') {
    return (
      <div className={`keen-slider__slide ${styles['homepage-slide']}`}>
        <HeroCard
          title={
            <Type size="xl" el="h2" style={{ color, marginTop: '-72px' }}>
              <span style={{ fontWeight: 500 }}>
                Analogue <sup>3D</sup>
              </span>
              <br />
              Welcome to another dimension.
              <br />
              64 bits of pleasure.
            </Type>
          }
          link={url}
        />
        <Background
          options={imgOptions}
          // @ts-ignore
          fallback={{
            src: fallbackImg?.url,
          }}
          fit="cover"
        />
      </div>
    )
  }
  const formattedTitle = getTitle(title)

  return (
    <div className={`keen-slider__slide ${styles['homepage-slide']}`}>
      {preload && (
        <Head>
          {images.map((img, index) => {
            const media =
              img.size === 'fallback'
                ? 'only screen and (min-width: 768px)'
                : img.size
            return (
              <link
                key={index}
                rel="preload"
                media={media}
                as="image"
                href={img.url}
              />
            )
          })}
        </Head>
      )}
      <HeroCard
        title={
          <Type size="xl" el={preload ? 'h1' : 'h2'} style={{ color }}>
            {formattedTitle}
          </Type>
        }
        link={url}
      />
      <Background
        options={imgOptions}
        // @ts-ignore
        fallback={{
          src: fallbackImg?.url,
        }}
        fit="cover"
      />
    </div>
  )
}
